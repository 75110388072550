export const projectData = [
   {
      imgPath: 'images/projects/pj0.png',
      title: 'Project 1',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj1.png',
      title: 'Project 2',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj2.png',
      title: 'Project 3',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj3.png',
      title: 'Project 4',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj4.png',
      title: 'Project 5',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj5.png',
      title: 'Project 6',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj6.png',
      title: 'Project 7',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj7.png',
      title: 'Project 8',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj8.png',
      title: 'Project 9',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
   {
      imgPath: 'images/projects/pj9.png',
      title: 'Project 10',
      description:
         'this is my project description yeah yeah yeah. lots of words to describe this project yeah yeah yeah. it is really in depth yeah yeah yeah. it has a lot of cool features yeah yeah yeah',
      github: 'https://github.com/ItsMeShane/shanes.chat',
      youtube: 'https://www.youtube.com/@its_me_shane',
      live: 'https://shanes.chat/',
   },
];
